import Layout from "../../components/layout"
import Seo from "../../components/seo";
import * as React from "react";
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image";
import BreadCrumbs from "../../components/BreadCrumbs";

const breadcrumbs = [
    {name: 'Kontakt', href: '/kontakt', current: true},
]

export default function Kontakt() {
    return (
        <Layout>
            <Seo title="Kontakt"/>
            <BreadCrumbs pages={breadcrumbs}/>
            <div className="bg-white">

                {/* Header */}
                <div className="py-12 bg-gray-50 sm:py-32">
                    <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
                        <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-gray-900 text-center sm:text-5xl sm:leading-none lg:text-6xl">
                            Kontaktieren Sie mich
                        </h1>
                        <p className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-gray-500 text-center">
                            Auch die beste Zusammenarbeit beginnt mit der ersten Kontaktaufnahme.<br/>Daher freue ich
                            mich,
                            von Ihnen zu hören!
                        </p>
                    </div>
                </div>

                {/* Contact Section */}
                <div className="relative bg-white">
                    <div className="lg:absolute lg:inset-0">
                        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <StaticImage
                                src="../../images/buero.jpg"
                                formats={["auto", "webp", "avif"]}
                                alt="Büro"

                                loading="eager"
                                className="h-56 w-full object-cover lg:absolute lg:h-full"
                            />

                        </div>
                    </div>
                    <div
                        className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
                        <div className="lg:pr-8">
                            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Lassen Sie uns
                                    zusammenarbeiten</h2>
                                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                                    Ich würde gerne mehr über Ihr Projekt erfahren. Am besten kontaktieren Sie mich per
                                    E-Mail und wir
                                    lernen uns kennen.
                                </p>
                                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                                    Gerne erläutere ich Ihnen persönlich meine Art, Software zu entwickeln.
                                    Ich bin zuversichtlich, dass ich Ihrem .NET- oder Delphi-Projekt weiterhelfen kann.
                                    Durch meine
                                    ausgeprägte Teamfähigkeit, kann ich mich in jedes Entwicklerteam integrieren.
                                </p>
                                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                                    Am Besten erreichen Sie mich unter:<br/>
                                    <a className="text-diit hover:text-yellow-500"
                                       href="mail:dirk@illenberger.de">dirk@illenberger.de</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="bg-diit">
                    <div
                        className="max-w-lg mx-auto text-center py-16 px-4 sm:max-w-2xl sm:py-24 sm:px-6 lg:px-8 lg:py-32">
                        <h2 className="text-3xl font-extrabold sm:text-4xl">
                            <span className="block text-white">Diesen Kunden konnte ich bislang weiterhelfen</span>

                        </h2>
                        <Link
                            to="/Projekte"
                            className="mt-8 w-full inline-flex items-center justify-center py-3 px-5 bg-white border border-transparent rounded-md shadow-md text-base font-medium text-diit hover:bg-blue-300 sm:w-auto"
                        >
                            <span>Zu meinen Projekten</span>

                        </Link>
                    </div>
                </div>


            </div>
        </Layout>

    )
}
